.singleView{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    grid-template-columns: 1fr 8px 1fr;
}

.singleView .staticTab{
    grid-template-rows: 48px 1fr;
}

.singleView .topBar {
    grid-template-columns: 1fr 48px;
}

.singleView .activity{
    grid-template-rows: 48px 1fr;
    max-height: calc(100vh - 122px);
}

.singleView .currentActivity {
    background-color: #f3f4f6;
    color: gray;
    position: relative;
    border: solid 1px gainsboro;
}

.singleView .alignmentContainer .columnDrag{
    left: 50%;
    top: 50%;
    right: unset;
    transform: translate(-50%, -50%);
}

.singleView .alignmentContainer:hover .columnDrag{
    visibility:visible;
    cursor:col-resize;
}

.singleView .alignmentContainer .columnDrag .bar{
    height: 50%;
}

.detailsTab{
    grid-template-rows: auto 1fr;
}

.detailsTab.inactive{
    grid-template-rows: 1fr;
}

.detailsTab .section{
    grid-template-rows: 32px repeat(auto-fill, 56px); /* First row 32px, others 56px */
    grid-auto-rows: 56px; /* Default row height for any additional rows */
    height: fit-content;
    width: 100%;
}

.section .header{
    grid-template-columns: 32px auto auto auto 1fr;
}

.section .header .dataPoint{
    padding: 4px 8px;
}

.inlineNavBar{
    grid-template-columns: 48px auto;
    width:fit-content;
}

.inlineNavBar:hover{
    color:dimgray;
}

.detailsInteraction .header{
    grid-template-columns: 24px auto;
}
.detailsInteraction.servicing{
    grid-template-columns: auto 1fr;
}

.detailsInteraction.servicing .nextPremium{
    grid-template-rows: 24px auto 1fr;
    background-color: #e8f5e9;
    border: solid 2px #4caf50;
    color: #4caf50;
}

.detailsInteraction.servicing .nextPremium .divider{
    background-color: #4caf50;
}

.detailsInteraction.servicing .nextPremium .data{
    grid-template-columns: repeat(2, 1fr);
}

.detailsInteraction.servicing .previousVOC{
    grid-template-rows: 24px auto 1fr;
    background-color: #e1f5fe;
    border: solid 2px #29b6f6;
    color: #29b6f6;
}

.detailsInteraction.servicing .previousVOC .divider{
    background-color: #29b6f6;
}

.detailsInteraction.servicing .previousVOC .data{
    grid-template-columns: repeat(4, 1fr);
}

.detailsInteraction.bids {
    grid-template-columns: repeat(2, 1fr);
    height:auto;
}

.detailsInteraction.bids .marketPrice.inactive, .detailsInteraction.bids .currentOffer.inactive{
    border:solid 2px gainsboro;
    color:dimgray;
    background-color: #f3f4f6;
}

.detailsInteraction.bids .currentOffer.active{
    color: #6a1b9a;
    border: solid 2px #6a1b9a;
    background-color: #f3e5f5;
    grid-template-columns: auto auto;
}

.detailsInteraction.bids .marketPrice.active{
    border: solid 2px #4caf50;
    color:#4caf50;
    background-color: #e8f5e9;
}

.detailsInteraction.bids .marketPrice .data, .detailsInteraction.bids .currentOffer .data{
    font-size: 28px;
}

.detailsInteraction.bids .currentOffer .date{
    grid-template-rows: repeat(2, 1fr);
}