.singleTimeline{
    gap:8px;
    width:100%;
    height:auto;
    background-color: #F3F4F6;
    overflow-x: hidden;
}
.bid .singleTimeline{
    grid-template-rows: auto 2px;
}
.bid .singleTimeline > *:nth-child(n+3) {
    grid-row: auto;
}
.timelinePostBlock{
    min-height: 76px;
    height: auto;
    width:calc(100% - 16px);
    grid-template-rows: 36px 2px minmax(36px, auto);
    grid-template-columns: 100%;
    background-color: white;
    border: solid 2px gainsboro;
}

.bidAlert.timelinePostBlock{
    grid-template-rows: 36px 2px auto minmax(36px, auto);
}

.timelinePostBlock:hover{
    background-color: #E2E2E2;
    color:dimgray;
    border-color:dimgray;
}

.timelinePostBlock:hover .divider{
    background-color: dimgray !important;
}

.timelinePostBlock.Lapsed{
    border: solid 2px #e65100;
    color:#e65100;
    background-color:#fff3e0;
}
.timelinePostBlock.Lapsed .divider{
    background-color:#e65100 !important;

}
.timelinePostBlock.Lapsed:hover{
    background-color:#ffe0b2;
}

.timelinePostBlock.Matured{
    border: solid 2px #311b92;
    color:#311b92;
    background-color:#ede7f6;
}
.timelinePostBlock.Matured .divider{
    background-color:#311b92 !important;
}
.timelinePostBlock.Matured:hover{
    background-color:#d1c4e9;
}

.timelinePostBlock.Sold{
    border: solid 2px #4caf50;
    color:#4caf50;
    background-color:#e8f5e9;
}
.timelinePostBlock.Sold .divider{
    background-color:#4caf50 !important;
}
.timelinePostBlock.Sold:hover{
    background-color:#c8e6c9;
}

/* Default Timeline */
.default.timelinePostBlock .header{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}

.default.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr;
}

.default.timelinePostBlock .content :nth-child(2){
    text-align: left;
    max-height: 100%;
    overflow: hidden;
}

.notificationAlert{
    border-radius: 50%;
    background-color: #00bfff;
    height:12px;
    width:12px;
}

/* Servicing Timeline */
/* VOC Timeline */
.voc.timelinePostBlock .header, .currentActivity .closed .header, .homePage .closed .header{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}
.voc.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr;
}

.voc.timelinePostBlock .completed{
    color:#29b6f6;

}
.voc.timelinePostBlock .open{
    color:#ef5350;
}
            
.voc.timelinePostBlock .submitted{
    color:#fbc02d;
}

.origination .homepage.voc.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) repeat(4, 1fr) 120px;
}

/* Payment Timeline */
.payment.timelinePostBlock .header{
    grid-template-columns: minmax(36px, auto) 1fr auto;
}

.payment.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}

.payment.timelinePostBlock .details{
    grid-template-columns: repeat(2, 1fr);
}
.payment.timelinePostBlock .details.alt{
    grid-template-columns: minmax(36px, auto) repeat(2, 1fr);
}

.payment.timelinePostBlock .details .prior svg{
    color:#ff9800;
}

.payment.timelinePostBlock .details.closed svg{
    color:#4caf50;
}

.closed.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) repeat(2, 1fr);
}
.closed.timelinePostBlock .divider{
    background-color: #E2E2E2;
}

/* Home Page Timeline */
.homepage.timelinePostBlock .header{
    grid-template-columns: minmax(36px, auto) auto 1fr;
}

.homepage.timelinePostBlock .details{
    grid-template-columns: minmax(36px, auto) repeat(4, 1fr) minmax(auto, 120px);
    gap:8px;
}

.homepage.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr;
}

.homepage.payment.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}

.homepage.timelinePostBlock .content div{
    grid-template-columns: minmax(calc(100% - 48px), 1fr) 48px;
}

.homepage.timelinePostBlock .ellipsisText{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
}

.homepage.payment.timelinePostBlock{
    grid-template-rows: minmax(36px, auto) 2px repeat(2, minmax(minmax(36px, auto), auto));
}

.origination .homepage.payment.timelinePostBlock{
    grid-template-rows: minmax(36px, auto) 2px minmax(36px, auto);
}

/* Home Page VOC */
.homepage.voc.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}

.voc.timelinePostBlock .details{
    grid-template-columns: minmax(36px, auto) repeat(3, 1fr) 96px;
}
.homepage.voc.timelinePostBlock .details{
    grid-template-columns: repeat(3, 1fr);
}

.homepage.timelinePostBlock .policyInfo{
    grid-template-columns: repeat(2, 1fr);
}

.origination .homepage.timelinePostBlock .policyInfo{
    grid-template-columns: minmax(36px, auto) repeat(4, 1fr) 120px;
}

.homepage.voc.timelinePostBlock{
    grid-template-rows: minmax(36px, auto) 2px minmax(36px, auto) repeat(2, minmax(36px, auto));
}

.origination .homepage.voc.timelinePostBlock{
    grid-template-rows: minmax(36px, auto) 2px minmax(36px, auto) minmax(36px, auto);
}

.homepage.bid.note.timelinePostBlock .bidNote{
    grid-template-columns: 100%;
}

/* Bid Timeline */

.bid.timelinePostBlock .header{
    grid-template-columns: minmax(36px, auto) 1fr 120px;
}

.bid.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) 1fr;
}

.bid.timelinePostBlock .content.bidContent{
    grid-template-columns: minmax(36px, auto) auto 1fr;
}
.bid.timelinePostBlock .content.bidContent .bidData{
    grid-template-columns: 100%;
    gap: 8px;
}
.bid.timelinePostBlock .content.bidContent .bidData .data{
    grid-template-columns: 1fr auto;
}

.bid.timelinePostBlock .details{
    grid-template-columns: calc(100% / 3) 1fr;
}
.bid.timelinePostBlock .details > div{
    grid-template-rows: 1fr;
}
.bid.timelinePostBlock .bidNote{
    grid-template-columns: minmax(36px, auto) 1fr;
}
.bid.timelinePostBlock.Completed .cover{
    border-radius: 24px;
    background-color: #43a047;
    color: #e8f5e9;
}
.bid.timelinePostBlock.Open .cover{
    border-radius: 24px;
    background-color: #6a1b9a;
    color: #f3e5f5;
}
.bid.timelinePostBlock.Denied .cover{
    border-radius: 24px;
    background-color: #ef5350;
    color: #ffebee;
}

.bid.timelinePostBlock .bidNote .vDivider{
    background-color:#6a1b9a;
}
.bid.underReviewAlertA.timelinePostBlock .bidNote .vDivider{
    background-color:#0d47a1;
}

.bidAlert.Open.timelinePostBlock{
    border:2px solid #6a1b9a;
    color:#6a1b9a;
    background-color:#f3e5f5;
}
.bidAlert.Open.timelinePostBlock .divider{
    background-color: #6a1b9a;
}
.bidAlert.Open.timelinePostBlock:hover{
    background-color: #e1bee7;
}
.bidAlert.Open.timelinePostBlock:hover .divider{
    background-color: #6a1b9a !important;
}

.bidAlert.Open.underReview.timelinePostBlock{
    border:2px solid #01579b;
    color:#01579b;
    background-color:#e3f2fd;
}
.bidAlert.Open.underReview.timelinePostBlock svg{
    color:#01579b;
}
.bidAlert.Open.underReview.timelinePostBlock .divider, .bidAlert.Open.underReview.timelinePostBlock .vDivider{
    background-color: #01579b;
}
.bidAlert.Open.underReview.timelinePostBlock:hover{
    background-color: #bbdefb;
}
.bidAlert.Open.underReview.timelinePostBlock:hover .divider, .bidAlert.Open.underReview.timelinePostBlock:hover .vDivider{
    background-color: #01579b !important;
}

.bidAlert.Completed.timelinePostBlock{
    border:2px solid #81c784;
    color:#43a047;
    background-color:#e8f5e9;
}
.bidAlert.Completed.timelinePostBlock svg{
    color:#43a047;
}
.bidAlert.Completed.timelinePostBlock .vDivider{
    background-color: #43a047;
}
.bidAlert.Completed.timelinePostBlock .divider{
    background-color: #43a047;
}
.bidAlert.Completed.timelinePostBlock:hover{
    background-color: #c8e6c9;
    border:2px solid #43a047;
}
.bidAlert.Completed.timelinePostBlock:hover .divider{
    background-color: #43a047 !important;
}

.bidAlert.Denied.timelinePostBlock{
    border:2px solid #e57373;
    color:#ef5350;
    background-color:#ffebee;
}
.bidAlert.Denied.timelinePostBlock svg{
    color:#ef5350;
}
.bidAlert.Denied.timelinePostBlock .vDivider{
    background-color: #ef5350;
}
.bidAlert.Denied.timelinePostBlock .divider{
    background-color: #ef5350;
}
.bidAlert.Denied.timelinePostBlock:hover{
    background-color: #ffcdd2;
    border:2px solid #ef5350;
}
.bidAlert.Denied.timelinePostBlock:hover .divider{
    background-color: #ef5350 !important;
}

.underReviewAlert.timelinePostBlock{
    border:2px solid #0d47a1;
    color:#0d47a1;
    background-color:#e3f2fd;
}
.underReviewAlert.timelinePostBlock .divider{
    background-color: #0d47a1;
}
.underReviewAlert.timelinePostBlock:hover{
    background-color: #bbdefb;
}
.underReviewAlert.timelinePostBlock:hover .divider{
    background-color: #0d47a1 !important;
}
.underReviewAlert.timelinePostBlock svg{
    color: #0d47a1 !important;
}

.timelinePostDetails{
    min-height:250px;
    width: 50%;
    grid-template-rows:48px 1fr;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: white;
    color:dimgray;
}

.timelinePostDetails .header{
    grid-template-columns: 48px 1fr 48px;
}

.timelinePostDetails .body{
    grid-template-columns: minmax(144px, auto) 1fr;
}

.timelinePostDetails .iconDisplay{
    grid-template-rows: minmax(36px, auto) 2px 1fr 2px minmax(36px, auto);
    gap: 8px;
}

.timelinePostDetails .icon svg{
    width: 72px;
    height: 72px;
}

.timelinePostDetails .content{
    grid-template-rows: minmax(72px, auto) 1fr minmax(36px, auto);
    gap: 8px;
}

.timelinePostDetails .details{
    grid-template-rows: repeat(3, 1fr);
}

.timelinePostDetails .actionBar{
    grid-template-columns: repeat(3, 1fr);
}

.timelinePostDetails .bidDetails{
    grid-template-rows: repeat(3, auto) 1fr;
}

.timelinePostDetails .vocDetails .data > div{
    grid-template-columns: 1fr auto;
}

.timelinePostDetails .paymentDetails{
    grid-template-columns: repeat(2, 50%);
}
.timelinePostDetails .paymentDetails .data > div{
    grid-template-columns: 96px 1fr;
}
.timelinePostDetails .paymentDetails .appliedInfo{
    grid-template-rows: minmax(36px, auto) 1fr minmax(36px, auto);
}

.timelinePostDetails .paymentDetails .appliedInfo svg{
    width:48px;
    height:48px;
}

.timelinePostBlock.bidAlert, .timelinePostBlock.underReviewAlert{
    width: calc(100% - 44px);
    position: sticky;
    left: 36px;
}

.bidAlert .timelineRequestTreeline{
    border-color:dimgray;
}

.underReviewAlert .timelineRequestTreeline{
    border-color:#0d47a1;
}

.followUpDetails{
    grid-template-rows: minmax(36px, auto) 1fr;
    width: 100%;
}

.homepage.bid.timelinePostBlock .content{
    grid-template-columns: minmax(36px, auto) repeat(4, 1fr) minmax(auto, 120px);
    gap:8px;
}

.homepage.bid.timelinePostBlock .header{
    grid-template-columns: minmax(36px, auto) auto 1fr;
}