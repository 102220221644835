.statusDetails .definition{
    grid-template-columns: auto 1fr;
}

.statusDetails .definition .icon{
    width: 64px;
    height: 64px;
}

.statusDetails .definition.Open.Request{
    color: #01579b;
    background-color: #e3f2fd;
    outline:solid 2px #01579b;
}

.statusDetails .definition.Closed.Request{
    color: #43a047;
    background-color: #e8f5e9;
    outline:solid 2px #43a047;
}

.statusDetails .definition.Open.Bid{
    color: #6a1b9a;
    background-color: #f3e5f5;
    outline:solid 2px #6a1b9a;
}

.statusDetails .definition.Denied.Bid{
    color: #ef5350;
    outline: solid 2px #ef5350;
    background-color: #ffebee;
}

.statusDetails .definition.Closed.Bid{
    color: #43a047;
    background-color: #e8f5e9;
    outline:solid 2px #43a047;
}

.statusDetails .definition .icon svg{
    width: 56px;
    height: 56px;
}

.statusDetails .definition .format{
    grid-template-rows: 48px auto;
}

.statusDetails .definition .format.response{
    grid-template-rows: 48px 24px auto;
}

.statusDetails .definition .format.request{
    grid-template-rows: 48px auto 32px;
}

.statusDetails .definition .format.bid{
    grid-template-rows: 48px 32px auto 32px;
}

.statusDetails .definition .format .header{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition .format .header .name{
    grid-template-columns: auto 1fr;
}

.statusDetails .definition.Closed.Request .format .checkStatus{
    padding:4px 8px;
    color: #e8f5e9;
    background-color:#43a047;
}

.statusDetails .definition.Open.Request .format .checkStatus{
    padding:4px 8px;
    color: #e3f2fd;
    background-color: #01579b;
}

.statusDetails .definition.Bid .offerData{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition.Bid .offerData .offer{
    grid-template-columns: 1fr auto;
}

.statusDetails .definition.Bid .offerData .offer .dataPoint{
    padding:4px 8px;
}

.statusDetails .definition.Closed.Bid .format .checkStatus, .statusDetails .definition.Bid.Closed .offerData .offer .dataPoint{
    padding:4px 8px;
    color: #e8f5e9;
    background-color:#43a047;
}

.statusDetails .definition.Open.Bid .format .checkStatus, .statusDetails .definition.Bid.Open .offerData .offer .dataPoint{
    padding:4px 8px;
    color: #f3e5f5;
    background-color: #6a1b9a;
}

.statusDetails .definition.Denied.Bid .format .checkStatus, .statusDetails .definition.Bid.Denied .offerData .offer .dataPoint{
    padding:4px 8px;
    color: #ef5350;
    background-color: #ef5350;
}