.bidTransition .format{
    grid-template-rows: 48px 1fr 48px !important;
}

.bidTransition .actionBar.bids{
    grid-template-columns: repeat(4, 1fr);
    height: 36px;
}

.bidTransition .actionBar.bids .action{
    height: 36px;
    grid-template-columns: 36px 1fr;
    background-color: white;
    border:solid 2px;
}

.bidTransition .actionBar.bids .action:nth-child(1){
    color: #00bfff;
    outline-color: #00bfff;
}

.bidTransition .actionBar.bids .action:nth-child(1):hover, .bidTransition .actionBar.bids .action:nth-child(1).selected{
    background-color: #e3f2fd;
}

.bidTransition .actionBar.bids .action:nth-child(2){
    color: #01579b;
    outline-color: #01579b;
}

.bidTransition .actionBar.bids .action:nth-child(2):hover, .bidTransition .actionBar.bids .action:nth-child(2).selected{
    background-color: #e3f2fd;
}

.bidTransition .actionBar.bids .action:nth-child(3){
    color: #6a1b9a;
    outline-color: #6a1b9a;
}

.bidTransition .actionBar.bids .action:nth-child(3):hover, .bidTransition .actionBar.bids .action:nth-child(3).selected{
    background-color: #f3e5f5;
}

.bidTransition .actionBar.bids .action:nth-child(4){
    color: #ef5350;
    outline-color: #ef5350;
}

.bidTransition .actionBar.bids .action:nth-child(4):hover, .bidTransition .actionBar.bids .action:nth-child(4).selected{
    background-color: #ffebee;
}

.bidTransition .saveBar .btn{
    width: 150px;
    grid-template-columns: 36px 1fr;
    border:solid 2px;
    border-color: #2196f3;
    color: #2196f3;
}

.bidTransition .saveBar .btn:hover{
    background-color: #e3f2fd;
}

.bidTransition .saveBar .inactive{
    background-color: #EFEFEF;
    border-color: dimgray;
    color: dimgray;
}

.bidTransition .saveBar .btn.loading{
    grid-template-columns: 1fr;
}