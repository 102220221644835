.actionBar.bid{
    grid-template-columns: repeat(4, 1fr);
    background-color: white;
}

.actionBar.bid:not(.alt){
    top:0;
    box-shadow: 0 0 8px #E2E2E2;
    border:solid 1px gainsboro;
    position:sticky;
}

.actionBar.bid .action{
    height: 36px;
    grid-template-columns: 36px 1fr;
    background-color: white;
    border:solid 2px;
}

.actionBar.bid .action:nth-child(1):not(.inactive){
    color: #00bfff;
    outline-color: #00bfff;
}

.actionBar.bid .action:nth-child(1):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(1):not(.inactive){
    background-color: #e3f2fd;
}

.actionBar.bid .action:nth-child(2):not(.inactive){
    color: #01579b;
    outline-color: #01579b;
}

.actionBar.bid .action:nth-child(2):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(2):not(.inactive){
    background-color: #e3f2fd;
}

.actionBar.bid .action:nth-child(3):not(.inactive){
    color: #6a1b9a;
    outline-color: #6a1b9a;
}

.actionBar.bid .action:nth-child(3):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(3):not(.inactive){
    background-color: #f3e5f5;
}

.actionBar.bid .action:nth-child(4):not(.inactive){
    color: #ef5350;
    outline-color: #ef5350;
}

.actionBar.bid .action:nth-child(4):not(.inactive):hover, .actionBar.bid .action.selected:nth-child(4):not(.inactive){
    background-color: #ffebee;
}

.actionBar.bid .action.inactive{
    color: dimgray;
    outline-color: #EFEFEF;
    background-color: #EFEFEF;
    border-color: #E2E2E2;
}

.timelineActivities{
    grid-template-rows: auto;
}

.newTimelinePost.default:hover, .newTimelinePost.inactive:hover{
    background-color: #e2e2e2;
    border-color: dimgray;
    color: dimgray;
}

.newTimelinePost.default:hover .divider, .newTimelinePost.default:hover .vDivider{
    background-color: dimgray;
}

.newTimelinePost.default{
    grid-template-rows: 36px auto minmax(36px, auto);
    grid-template-columns: 100%;
    background-color: white;
    border: solid 2px gainsboro;
}
.newTimelinePost.default.note > .content{
    grid-template-columns: 36px 1fr;
    grid-auto-rows: 36px auto;
}

.newTimelinePost.default > .header{
    grid-template-columns: 36px auto 1fr;
}

.newTimelinePost.default > .content{
    grid-template-columns: 36px auto 1fr;
    grid-auto-rows: 36px auto;
}

.treelinePost{
    grid-template-columns: 16px 1fr;
}

.treelinePost .treeline{
    height: calc(100% + 6px);
    width: 1px;
    border: 1px gray dashed;
}

.newTimelinePost.default.offer.Open{
    color: #6a1b9a;
    border-color: #6a1b9a;
    background-color: #f3e5f5;
}

.newTimelinePost.default.offer.Completed, .newTimelinePost.default.offer.Closed{
    color: #43a047;
    border-color: #43a047;
    background-color: #e8f5e9;
}

.newTimelinePost.default.offer.Denied{
    color: #ef5350;
    border-color: #ef5350;
    background-color: #ffebee;
}

.newTimelinePost.default.offer.Open:hover{
    background-color: #e1bee7;
}

.newTimelinePost.default.offer.Completed:hover, .newTimelinePost.default.offer.Closed:hover{
    background-color: #c8e6c9;
}

.newTimelinePost.default.offer.Denied:hover{
    background-color: #ffcdd2;
}

.newTimelinePost.default.offer.Open .dT{
    color: #f3e5f5;
    border-color: #6a1b9a;
    background-color: #6a1b9a;
}

.newTimelinePost.default.offer:not(.Open, .Completed, .Denied, .Closed) .dT{
    color: dimgray;
    border-color: #E2E2E2;
    background-color: #E2E2E2;
}

.newTimelinePost.default.offer.Completed .dT, .newTimelinePost.default.offer.Closed .dT{
    color: #e8f5e9;
    border-color: #43a047;
    background-color: #43a047;
}

.newTimelinePost.default.offer.Denied .dT{
    color: #ffebee;
    border-color: #ef5350;
    background-color: #ef5350;
}

.newTimelinePost.default.offer .dataPoint, .newTimelinePost.default.request .dataPoint{
    padding:4px 8px;
}

.newTimelinePost.inactive{
    height:74px;
    grid-template-columns: 100%;
    background-color: white;
    border: solid 2px gainsboro;
}

.newTimelinePost.default.request.Open{
    border: 2px solid #01579b;
    color: #01579b;
    background-color: #e3f2fd;
}

.newTimelinePost.default.request.Open:hover{
    background-color: #bbdefb;
}

.newTimelinePost.default.request.Open .dT{
    color:#e3f2fd;
    background-color: #01579b;
}

.newTimelinePost.default.request.Completed, .newTimelinePost.default.request.Closed{
    border: 2px solid #81c784;
    color: #43a047;
    background-color: #e8f5e9;
}

.newTimelinePost.default.request.Completed:hover, .newTimelinePost.default.request.Closed:hover{
    background-color: #c8e6c9;
}

.newTimelinePost.default.request.Completed .dT, .newTimelinePost.default.request.Closed .dT{
    color: #e8f5e9;
    background-color: #43a047;
}