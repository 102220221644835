
.sideBar{
    z-index: 2;
    gap:16px;
    height: 100%;
}

.sideBar.pC1{
  grid-template-columns: repeat(1, 90px);
}
.sideBar.pC2{
  grid-template-columns: repeat(2, 90px);
}
.sideBar.pC3{
  grid-template-columns: repeat(3, 90px);
}
.sideBar.pC4{
  grid-template-columns: repeat(4, 90px);
}
.sideBar.pC5{
  grid-template-columns: repeat(5, 90px);
}
.sideBar.pC6{
  grid-template-columns: repeat(6, 90px);
}
  
.sideBarInner{
  width:100%;
  height:100%;
}

.sideBarBtn{
  color: dimgray;
  cursor:pointer;
  grid-template-rows: 1fr 24px;
  font-size:16px;
}
.sideBarBtn.selected{
  color: #d2ab66;
  font-weight: 500;
  cursor:pointer;
}

.sideBarBtn svg{
  width:36px;
  height:36px;
}

/* #d2ab66;
#104735 */


.sideBarBtn:hover{
  color:#104735;
  /* background-color: #719085; */
}

.sideBarBtn.selected:hover:not(.pages .sideBarBtn){
  color:#104735;
}
.pages .sideBarBtn.selected:hover{
  color: #d2ab66;
}