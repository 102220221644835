input{
    border: 2px solid transparent;
    border-bottom-color: #efefef;
    font-size: 18px;
    height: 32px;
    overflow: hidden;
    padding: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

input:focus {
    outline: none;
}

.placeholder{
    color: gray;
    font-size: 18px;
    font-weight: 400;
    padding-left: 8px;
}

.form .row > div{
    grid-template-rows: 24px 32px;
}