.btn.download{
    border: 2px solid #42a5f5;
    color: #0d47a1;
    grid-template-columns: 1fr 24px;
}

.btn.download.progress{
    grid-template-columns: 1fr
}

.btn.download:hover{
    background-color: #e3f2fd;
    border-color: #0d47a1;
}

.btn.download.multi{
    grid-template-columns: unset;
    position: relative;
    z-index: 2;
    max-height: 32px;
    min-width: 120px;
}

.btn.download.multi:not(.progress) > div:first-of-type{
    grid-template-columns: 1fr 24px;
    height: 28px;
}

.download.multi.list{
    background-color: white;
    height: auto;
    width:100%;
    border:solid 2px #E2E2E2;
    box-shadow: 0 0 8px #E2E2E2;
}

.download.multi.list .item{
    grid-template-columns: 24px 1fr 24px;
    color:dimgray;
}

.download.multi.list .item.selected{
    color:#0d47a1;
}

.download.multi.list .item:hover{
    background-color: #e3f2fd;
    color:#2196f3;
}