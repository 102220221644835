.sizeMid, .sizeMin, .sizeMobile{
  display:none;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
  background-color: transparent;
}

body * {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  transition: 100ms;
}
.homePagePieChart, .dropdownList *, .formCanvas, .formCanvasHalf, .formCanvas *, .formCanvasHalf *, .notificationBoardBlock *, .policiesDetailsBlock *, .switchViewBtnOption, .homePageTimeline *{
  transition: 0ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color:#C5D5D6;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  overflow: hidden;
}

.App > div:not(.loginBoard){
  display: grid;
  grid-template-rows: 90px;
  height:100vh;
  width:100vw;
  grid-template-columns: 100%;
  
}
.App > .default{
  background-color:#C5D5D6;
}

.appHeader{
  background-color:white;
  height:90px;
  z-index:2;
}

.appBoard{
  position: relative;
  width:100%;
  height:100%;
  z-index:2;
  max-width:100%;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

.appActivity{
  position: relative;
  height: calc(100vh - 90px);;
  width: 100%;
  z-index: 1;
  text-align: center;
  display: flex;
}

.appActivity > div{
  width:100%;
  height:100%;
  padding:16px;
}

a{
  color:dimgrey;
  text-decoration: none;
}

a * svg{
  color:inherit;
}

.submitBtn{
  background-color:#C5E3D4;
  width:200px;
  height:50px;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  color:white;
  font-weight: 700;
  position:absolute;
  bottom:10px;
}
.submitBtn:hover{
  cursor:pointer;
}

.closeBtn{
  width:40px;
  height:40px;
  border-radius:50%;
  position:absolute;
  top:5px;
  right:20px;
  text-align:center;
  color:white;
  z-index: 2;
}

.closeBtn:hover{
  cursor:pointer;
  background-color:whitesmoke;
  border-radius:50%;
}
.closeBtn svg {
  position:absolute;
  margin: auto;
  display: block;
  bottom: 0px;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  color:gray;
}
.closeBtn:hover svg{
  color:darkgray;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-thumb {
  background: dimgray;
  border: 4px white solid;
  background-clip: padding-box;
  border-radius: 18px;
}

.wO ::-webkit-scrollbar-thumb {
  background: dimgray !important;
  border: 4px white solid !important;
  background-clip: padding-box !important;
  border-radius: 18px !important;
}
.wO ::-webkit-scrollbar-track {
  background: white !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: white;
}

.moduleBlock{
  -moz-box-shadow: 0 0 3px gray;
  -webkit-box-shadow: 0 0 3px gray;
  box-shadow: 0 0 8px gray;
}

.inputField{
  display:inline-block;
  text-align: left;
  line-height: 32px;
  vertical-align: bottom;
  position: relative;
  height:auto;
  width:100%;
  font-weight:100;
  gap:4px;
}

.fieldCouple{
  height:56px;
}

.subFieldCouple{
  width:47.5%;
  display: inline-block;
}

div > .subFieldCouple ~ .subFieldCouple{
  float:right;
}

.inputField input{
  width: 100%;
  height: 32px;
  border-radius:5px !important;
  border-style: none;
  background-color: #F3F4F6;
  padding: 8px;
  border: solid 1px gainsboro;
}

.inputField.passwordContainer{
  height:105px;
}

.passwordRequirements{
  position: relative;
  border-radius:12px;
  width:100%;
  height:42px;
  line-height: 16px;
  z-index: 2;
  color:gray;
}

.passwordRequirements.active{
  color:#EF5350;
}

.inputField input.active{
  width: 100%;
  height: 32px;
  border-style: none;
  background-color: white;
  padding: 8px;
  border: solid 1px gainsboro;
}
.inputField input.preFace{
  padding-left:15px;
}
.inputField input:read-only{
  color:gray;
}
.inputField input:focus{
  box-shadow: inset 0 0 2px #ccc;
  outline:none;
}
.fieldName{
  height:24px;
  padding-left:10px;
  font-size:14px;
  font-weight: 400;
}

.showPasswordBtn{
  position:absolute;
  border-radius:50%;
  height:24px;
  width:24px;
  right:-28px;
  bottom:4px;
  color:gray;
}
.showPasswordBtn:hover{
  cursor:pointer;
}

.toggleContainer{
  height: auto;
  width: 100%;
  display: inline-block;
  line-height: 40px;
  background-color: inherit;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Edge, Opera and Firefox */
}

.toggleTitle{
  width: auto;
  float: left;
}
.toggleInnerWrap{
  background-color:#00BFFF;
  border-radius:40px;
  width:100%;
  height:100%;
  box-shadow: inset 0 0 0 2px #00BFFF;
  text-align: right;
  width:80px;
  height:40px;
  float:right;
}
.toggleInnerWrap.off{
  text-align: left;
  box-shadow: inset 0 0 0 2px #E2E2E2;
  background-color:#EFEFEF;
}
.toggleInnerWrap:hover{
  cursor: pointer;
}

.toggleBtn{
  width:40px;
  height:40px;
  border-radius:50%;
  background-color:white;
  box-shadow: inset 0 0 0 2px #00BFFF;
  display:inline-block;
  -moz-box-shadow: inset 0 0 4px #E2E2E2;
  -webkit-box-shadow: inset 0 0 4px #E2E2E2;
  box-shadow: inset 0 0 4px #E2E2E2;
  border:#00BFFF solid 2px;
}
.toggleInnerWrap.off .toggleBtn{
  background-color:white;
  -moz-box-shadow: inset 0 0 4px #E2E2E2;
  -webkit-box-shadow: inset 0 0 4px #E2E2E2;
  box-shadow: inset 0 0 4px #E2E2E2;
  border:#E2E2E2 solid 2px;
}

.toggleCount{
  display: inline-block;
  border-radius: 40px;
  background-color: #F3F4F6;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 5px;
  font-weight:700;
  color:gray;
}

.inlineBtnOption{
  width: auto;
  height: 24px;
  line-height:24px;
  display: inline-block;
  color: inherit;
  position: absolute;
  margin: auto;
  display: block;
  top: 4px;
  left: 8px;
  max-height: 100%;
  max-width: 100%;
}

.inlineBtnIcon{
  width: 24px;
  height: 24px;
  background-color: white;
  color: #42a5f5;
  border-radius: 50%;
  line-height:24px;
  outline:2px solid white;
}

.btnCounter{
  display: inline-block;
  border-radius: 50%;
  background-color: silver;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-weight: 700;
  color: white;
  border: solid 1px gray;
}

.switchViewBtn{
  grid-template-columns: calc(100% - 28px) 28px;
}

.switchViewBtnOption{
  color: dimgray;
  z-index: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  width: 100%;
}

.switchViewBtn:hover{
  cursor:pointer;
  background-color:#EFEFEF;
  color:gray;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                      supported by Chrome, Edge, Opera and Firefox */
}
.switchViewBtn.selected{
  border-bottom: solid 4px #00bfff;
  line-height: 36px;
  font-weight: 500;
  box-sizing: border-box;
}
.switchViewBtn.selected.inactive, .switchViewBtn.inactive{
  border-color: gray;
}

.loading{
  color:gray !important;
  animation: loadingAnimation ease;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-fill-mode: both;
}
.loading.themed *{
  color:#00bfff;
}

@keyframes loadingAnimation{
    0% {
      background-color: #EFEFEF;
    }
  
    25% {
      background-color: #E2E2E2;
    }
  
    50% {
      background-color: #CCC;
    }

    75% {
      background-color: #E2E2E2;
    }
    100% {
      background-color: #EFEFEF;
    }
}

@keyframes loadingAnimationDarkMode{
  0% {
    background-color: #212121;
  }

  50% {
    background-color: #5E5E5E;
  }

  100% {
    background-color: #212121;
  }
}


.inlineNav{
  height: 46px;
  width: 175px;
  line-height: 46px;
  grid-template-columns: 46px calc(100% - 46px);
}

.inlineNav:hover .inlineNavDirection{
  color:#E2E2E2;
}

.inactive{
  cursor:default !important;
}
.inactive .inlineBtnIcon{
  color:gray;
}

.radioOptionField{
    display: inline-block;
    text-align: left;
    line-height: 32px;
    position: relative;
    height: 56px;
    width: 100%;
    font-weight: 100;
}
.radioOption{
  width: auto;
  height: 28px;
  border-radius: 20px;
  border-style: none;
  background-color: inherit;
  text-align: left;
  display:inline-block;
  vertical-align: middle;
  margin-right: 35px;
  color:dimgray;
  position:relative;
}
.radioOptionBtn{
  width: 28px;
  height: 28px;
  border-radius: 20px;
  border: solid 1px gainsboro;
  display: inline-block;
  position: relative;
}
.radioSelection{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width:17px;
  height:17px;
  border-radius: 50%;
  background-color:#00BFFF;
}
.radioOptionBtn:hover{
  cursor:pointer;
  box-shadow: inset 0px 0px 4px #ccc;
}
.radioOptionName{
  height:28px;
  line-height:28px;
  width: auto;
  display: inline-block;
}

.checkboxField{
  height:20px;
  text-align: left;
  line-height: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.checkboxToggle{
  width:20px;
  height:20px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  border:solid 1px gray;
  position: relative;
}
.checkboxToggle.selected{
  background-color:#00BFFF;
  border:white;
}
.checkboxToggle.selected:hover svg{
  color: gray;
}
.checkboxToggle.selected:hover{
  background-color: white;
  border:solid 1px gray;
}
.checkboxToggle:hover{
  background-color: #EFEFEF;
  cursor:pointer;
}
.checkboxField .fieldName{
  display:inline-block;
  line-height: 20px;
}
.checkboxToggle svg{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color:white;
  width:90%;
  height:90%;
}

input[type=date]{
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color:gray;
  /* line-height: 32px; */
}

input[type="date"]::-webkit-calendar-picker-indicator{
  width:25px;
  height:25px;
  cursor:pointer;
  color-scheme: default;
  opacity:0;
  z-index: 1;
}

.datePlaceholder{
  position: absolute;
  right:50px;
  top:26px;
  z-index: 0;
  height:30px;
  width:auto;
  line-height:30px;
  color:gray;
  font-size:14px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.inputField svg{
  position: absolute;
  right:10px;
  top:29px;
  cursor:pointer;
  z-index: 0;
}
.inputFieldContainer{
  position: relative;
}
.inputField .dateAge{
  position: absolute;
  right:40px;
  top:25px;
  z-index: 0;
  color:gray;
  font-size:14px;
}
.dropdownList{
  position: absolute;
  z-index: 2;
  background-color:white;
  border-radius: 5px;
  -moz-box-shadow: 0 0 8px gray;
  -webkit-box-shadow: 0 0 8px gray;
  box-shadow: 0 0 8px gray;
  overflow: hidden;
  width:95%;
  left:50%;
  top:calc(100% + 4px);
  transform: translate(-50%, 0);
}
.dropdownList.invert{
  position: absolute;
  left: 50%;
  top: unset;
  bottom: 36px;
}

.dropdownListInner{
  max-height:150px;
  overflow-y: auto;
}

.dropdownListItem{
  display:none;
  color:dimgray;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.dropdownList.active .dropdownListItem{
  display:flex;
}

.dropdownListItem{
  cursor:pointer;
}

.dropdownListItem:hover{
  color:white;
  background-color:#00BFFF;
}

.dropdownListItem p{
  padding-left: 5px;
  line-height: 20px;
}
.inputPreface{
  position: absolute;
  left: 5px;
  cursor: pointer;
  z-index: 0;
  color: dimgray;
  font-weight: 500;
}

.inputField.error input{
  border-color:#EF5350;
}

.inputField.error .fieldName{
  color:#EF5350;
}

.miniModuleHeader{
  position: sticky;
  height: 48px;
  line-height: 24px;
  text-align: center;
  top: 0;
  z-index: 2;
  background-color: inherit;
}
.miniModuleHeader .closeBtn{
  right:5px;
}
.miniModuleHeaderTitle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
}
.miniModuleBody{
  width: 100%;
  height: auto;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  top:48px;
}

/* No Select */
.ns{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.assignTeamMemberList{
  display:inline-block;
  width:calc(100% - 16px);
  min-height:200px;
  max-height:400px;
  overflow-y:auto;
}

.assignTeamMemberRow{
  display:inline-grid;
  width:100%;
  height:36px;
  margin-top:5px;
  border-radius: 5px;
  border:solid 1px #E2E2E2;
  grid-template-columns: calc(50% - 25px) calc(50% - 25px) 50px;
  cursor:pointer;
  color:dimgray;
}
.assignTeamMemberRow:hover{
  background-color:#EFEFEF;
}
.assignTeamMemberRow.active{
  border: solid 1px #E2E2E2;
  color: #4caf50;
  border-color: #c8e6c9;
  background-color: #e8f5e9;
}
.assignTeamMemberRow svg{
  color:#E2E2E2;
}
.assignTeamMemberRow.active svg{
  color:#00c853;
}
.assignTeamMemberListName{
  justify-content: start;
  justify-items: flex-start;
  align-content: space-evenly;
  align-items: center;
  display: inline-grid;
  padding-left:8px;
}
.assignTeamMemberListCheck{
  display: inline-grid;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
}
.assignRowText{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

/* Dark Mode */

.darkMode{
  background-color: #151515;
}

.darkMode .appHeader{
  background-color:#212121;
}

.darkMode .sideBar, .darkMode .moduleBlock{
  background-color:#212121;
  box-shadow: 0 0 8px black;
}

.darkMode .mobileAltOptions svg{
  color:#719085;
}

.darkMode .timeline, .darkMode .notificationTimeline{
  background-color: #2E2E2E;
  box-shadow: none;
}
.darkMode .timelinePost, .darkMode .policyRow, .darkMode .notificationRow{
  background-color:#212121;
  color:white;
  border:none;
}
.darkMode .timelinePost:hover, .darkMode .policyRow:hover, .darkMode .notificationRow:hover{
  background-color:#5E5E5E;
  color:white;
  border:none;
}

.darkMode .timelineHeader{
  background-color:#212121;
  color:white;
}

.darkMode .listBar{
  display:none;
}

.darkMode ::-webkit-scrollbar-thumb, .darkMode .singlePolicyCurrentActivity ::-webkit-scrollbar-thumb{
  background-color:#2E2E2E;
  border-color:transparent;
}

.darkMode ::-webkit-scrollbar-track, .darkMode .singlePolicyCurrentActivity ::-webkit-scrollbar-track{
  background-color:transparent;
}

.darkMode .policyDetails::-webkit-scrollbar-thumb, .darkMode .singlePolicyCurrentActivity ::-webkit-scrollbar-thumb{
  background-color:#212121;
  border-color:transparent;
}
.darkMode .policyDetails::-webkit-scrollbar-track, .darkMode .singlePolicyCurrentActivity ::-webkit-scrollbar-track{
  background-color:#2E2E2E;
}

.darkMode .dropdownList ::-webkit-scrollbar-thumb{
  background-color: #212121;
}
.darkMode .fieldName{
    color:gray;
    font-weight: 700;
}

.darkMode .inputField input{
  background-color:#212121;
  border:none;
  color:gray;
}
.darkMode .inputPreface{
  color:dimgray;
}
.darkMode input.active::placeholder {
  color: #212121 !important;
}
.darkMode .uploadModule input.active, .darkMode .profileModule input.active, .darkMode .confirmationModule input.active{
  background-color:gainsboro;
  color:#212121;
}
.darkMode .uploadModule input::placeholder, .darkMode .profileModule input::placeholder, .darkMode .confirmationModule input::placeholder{
  color:gray !important;
}
.darkMode .dropdownListItem{
  color:gainsboro;
}
.darkMode .dropdownListItem:hover{
  background-color: #5E5E5E;
  color: white;
}
.darkMode .uploadModule input, .darkMode .profileModule input, .darkMode .confirmationModule input{
  background-color:#2E2E2E;
}

.darkMode .radioOptionBtn{
  background-color: #2E2E2E;
  border-color: transparent;
}

.darkMode .radioOptionBtn:hover{
  box-shadow: inset 0px 0px 4px #5E5E5E;
  border-color: #5E5E5E;
}

.darkMode .toggleInnerWrap.off{
  background-color: #2E2E2E;
  box-shadow:inset 0 0 0 2px #5E5E5E;
}

.darkMode .inlineBtnIcon{
  background-color:#2E2E2E;
  outline-color: #2E2E2E;
}

.darkMode .switchViewBtn:hover{
  background-color:#2E2E2E;
}

.darkMode .pieChartSummary{
  background-color:#2E2E2E;
  box-shadow: none;
}

.darkMode .policiesDetailsBlockTitle{
  color:white;
}

.darkMode .policiesFiltersModule {
  box-shadow:0 0 8px black;
}
.darkMode .listAltercationOption{
  color:black;
}

.darkMode .policiesDetailsBoard{
  background-color:#2E2E2E;
  box-shadow:none;
}

.darkMode .policiesFiltersHeader, .darkMode .policiesFiltersFooter{
  background-color:#212121;
}

.darkMode .policiesFiltersToggleSection{
  background-color:#2E2E2E;
  box-shadow: none;
}

.darkMode .toggleContainer{
  color:white;
}
.darkMode .policiesFiltersToggleSection .toggleContainer{
  background-color:#212121 !important;
  color:white;
  border:none !important;
}

.darkMode .policiesFiltersFooter{
  bottom:-1px;
}

.darkMode .policiesColumnHeader{
  color:gray;
}

.darkMode .policiesSearchBar{
  background-color:#2E2E2E;
  color:white;
}

.darkMode .noPolicyResults{
  background-color: #2E2E2E;
  box-shadow: none;
}

.darkMode .loading{
  background-color: #2E2E2E;
  color:gray !important;
  animation: loadingAnimationDarkMode ease;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-fill-mode: both;
}

.darkMode .noTimelineResults{
  box-shadow: none;
}

.darkMode .listAltercationOption{
  background-color:#2E2E2E;
  border-color: transparent;
  color:white;
}
.darkMode .listAltercationOption:hover{
  color:gray;
}

.darkMode .policiesFiltersRangeSliderSection{
  background-color: #2E2E2E;
  box-shadow: none;
}

.darkMode .policiesSearchBarWrapper{
  background-color: inherit;
}
.darkMode .policiesSearchBar{
  border-color:transparent;
}

.darkMode .MuiBox-root span{
  color:white;
}

.darkMode .policyRangeInfo{
  color:white;
}

/* Single Policy View */

.darkMode .policyDetailsCardFaceInner::-webkit-scrollbar-thumb{
  background-color:#212121;
  border-color:transparent;
}
.darkMode .policyDetailsCardFaceInner::-webkit-scrollbar-track{
  background-color:#2E2E2E;
}

.darkMode .singlePolicyTimelineTracker, .darkMode .policyDetailsCardTitle, .darkMode .policyDetailsCardFace, .darkMode .singlePolicyTimeline, .darkMode .policyDetailsCard{
  background-color: #2E2E2E;
  box-shadow: none;
}

.darkMode .singlePolicyBlockOptionBar{
  background-color:#212121;
  font-weight: 700;
  color:white;
}

.darkMode .timelineTrackBar{
  border-color: #212121;
  background-color:#5E5E5E;
}

.darkMode .policyDetailsCardTitle span{
  color:white;
}

.darkMode .notificationHeader{
  background-color: inherit;
}

.darkMode .statusDetailBlock, .darkMode .notificationBoardInner, .darkMode .confirmationModule{
  box-shadow:0 0 8px black;
}

.darkMode .statusDefinitionText{
  color:white;
}

.darkMode .overViewDataBlock{
  margin-top:15px;
}
.darkMode .overViewDataTitle{
  color:white;
}

.darkMode .summaryStatementBidValue{
  background-color: #212121;
  color:gainsboro;
}

.darkMode .timelineRequest{
  background-color:#212121;
  color:#E2E2E2;
  border-color: transparent;
}

.darkMode .bidPanelTable{
  background-color:#2E2E2E;
  box-shadow: none;
}
.darkMode .bidPanelDetail{
  background-color: #212121;
  color:white;
  border-color: #2E2E2E;
  box-shadow: none;
}
.darkMode .bidPanelDownloadBtn{
  background-color:#212121;
}

.darkMode .currentActivity{
  background-color: #2E2E2E;
}

.darkMode .closingTab::-webkit-scrollbar-track{
  background-color: #2E2E2E;
}
.darkMode .closingTab::-webkit-scrollbar-thumb{
  border-color: #2E2E2E;
  background-color:#212121;
}
.darkMode .closingAssignment{
  color:gainsboro;
}

.darkMode .closingTabEmptyInner{
  background-color: inherit;
}

/* Messages */

.darkMode .messageBoxSendBtn{
  bottom: 5px;
  right: 5px;
}

.darkMode .messageBoardMsgBox{
  vertical-align: inherit;
  width: calc(100% - 8px);
}

.darkMode .documentsBlock{
  width:100%;
  background-color: inherit;
}

.darkMode .policyDetailsCardTitleSmall span{
  color:white !important;
  font-weight: 100;
}

.darkMode .policyDetailsMessageBoard{
  padding-left: 15px;
  padding-right: 15px;
}

.darkMode .bidPanelTable{
  padding-top:6px;
}
/* HomePage */

.darkMode .homePagePie{
  box-shadow: none;
}
.darkMode .pieTotalCount *, .darkMode .summaryInfoBlockName, .darkMode .summaryInfoBlockPercentage, .darkMode .summarySectionTitle{
  color:#E2E2E2 !important;
}
.darkMode .homePageColumnHeader div{
  color:#E2E2E2 !important;
}

.darkMode .pieTotalCountContainer{
  background-color:#212121;
}

.darkMode .pieTotalCount{
  background-color: #2E2E2E;
  box-shadow: none;
}

.darkMode .homePageInfoPanelTitle{
  color:white;
}

.darkMode .policyPositionSummaryTitle{
  color:white;
}

.darkMode .breakdownTimeline{
  background-color: #2E2E2E;
  box-shadow:none;
}

.darkMode .breakdownTimelineRow{
  background-color: #212121;
  border-color:transparent;
  color:white;
}
.darkMode .homePageTotalBreakdown{
  background-color:#2E2E2E;
  box-shadow: none;
}
.darkMode .overViewDataTotalValOption{
  color:white;
}
.darkMode .overViewDataTopSection{
  background-color:#212121;
}

/* Upload */

.darkMode .dropdownList{
  background-color:#2E2E2E;
  box-shadow:0 0 8px black;
}

.darkMode .policyDetailsHeader{
  color:white;
}

.darkMode .primaryTitle, .darkMode .secondaryTitle{
  color:#E2E2E2;
}
.darkMode .saveUploadData.active{
  font-weight: 500;
  box-shadow: inset 0 0 0 4px #00bfff;
}
/* Profile */
.darkMode .profileModuleTitle{
  color:white;
}
.darkMode .profileDetailsHeader, .darkMode .changePassword{
  color:#E2E2E2;
}

/* NavBar */

.darkMode .logo{
  background-color: transparent;
}

.darkMode .abacusHeader, .darkMode .currentPageHandlerPath{
  color:#719085;
}
.darkMode .currentPageHandlerPath:hover{
  background-color: #719085;
  color:#104735;
}

/* SideBar */

.darkMode .sideBarBtn, .darkMode .sideBarBtn:hover{
  color:#719085;
}
.darkMode .sideBarBtn:hover{
  color:#719085 !important;
}
.darkMode .sideBarBtn:hover svg{
  color:#104735;
}
.darkMode .sideBarAccBtnSection svg{
  color:#719085;
}
.darkMode .sideBarAccBtnSection .selected svg{
  color:#d2ab66;
}

/* Leads */

.darkMode .lead .leadBlock, .darkMode .lead.leadBlock{
  background-color: #2E2E2E;
}
.darkMode .contactAttempted .leadBlock, .darkMode .contactAttempted.leadBlock{
  background-color: #2E2E2E;
}
.darkMode .inProgress .leadBlock, .darkMode .inProgress.leadBlock{
  background-color: #2E2E2E;
}
.darkMode .leadsCategoryHeaderInnerWrap div.lead, .darkMode .leadsCategoryHeaderInnerWrap div.contactAttempted, .darkMode .leadsCategoryHeaderInnerWrap div.inProgress, .darkMode .leadsCategoryHeaderInnerWrap div.closed {
  background: gainsboro;
}
.darkMode .leadsSearchBar{
  background-color: #2E2E2E;
  color:gainsboro;
}
.darkMode .leadsSearchBarSwitch{
  background-color: #00bfff;
  color:white;
  outline:unset;
}
.darkMode .leadsListBoard{
  background-color: #212121;
}
.darkMode .leadDetailsCardTitle{
  color:white;
}
.darkMode .leadDetailsCardFace{
  background-color:inherit;
  box-shadow: unset;
}

.prompt.alt .complete svg{
  color:#66bb6a;
}
